import React from "react";
import { Helmet } from "react-helmet";

// import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children }) => {
  const { title } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <link
          rel="alternate"
          type="application/rss+xml"
          href="https://www.pineapplemoney.com/rss.xml"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />

        <script data-grow-initializer="">
        {`
          !(function(){
              window.growMe || (
                  (window.growMe = function(e){
                      window.growMe._.push(e);
                  }),
                  (window.growMe._ = [])
              );
              
              var e = document.createElement("script");
              e.type = "text/javascript";
              e.src = "https://faves.grow.me/main.js";
              e.defer = true;
              e.setAttribute("data-grow-faves-site-id", "U2l0ZTpkOGM0NDJiZi02YTcwLTQxNmItODQzNy1lNmI0YTkxY2RhNjI=");
              
              var t = document.getElementsByTagName("script")[0];
              t.parentNode.insertBefore(e, t);
          })();
        `}
      </script>
      {/* This is the script for ads */}
      <script type="text/javascript" async="async" data-noptimize="1" data-cfasync="false" src="//scripts.scriptwrapper.com/tags/d8c442bf-6a70-416b-8437-e6b4a91cda62.js" />
      </Helmet>
      <Navbar />
      {/* <Banner /> */}
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
