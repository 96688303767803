import React from "react";
import { Link } from "gatsby";

import linkedin from "../img/social/linkedin.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import youtube from "../img/social/youtube.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <div className="container">
            <div className="columns">

              <div className="column is-3">
                <section className="menu">
                  <h4 className="footer-header">COMPANY</h4>
                  <ul className="menu-list">
                    <li>
                      <Link className="footer-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/disclosure">
                        Disclosure
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/terms-of-use">
                        Terms Of Use
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-3">
                <section>
                  <h4 className="footer-header">PARTNERSHIPS</h4>
                  <ul className="menu-list">
                    {/* add these pages */}
                    <li>
                      <Link className="footer-item" to="/work-with-us">
                        Work With Us
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/work-for-us">
                        Work For Us
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-3">
                 <section>
                  <h4 className="footer-header">MOST POPULAR</h4>
                  <ul className="menu-list">
                    <li>
                      <Link className="footer-item" to="/how-to-start-a-blog">
                        How to Start a Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/make-money/compound-interest-investments">
                        Best Compound Interest Investments
                      </Link>
                    </li>
                    {/* add more most popular items */}
                    <li>
                      <Link className="footer-item" to="/make-money/best-apps-to-earn-gift-cards">
                        Best Apps to Earn Gift Cards and Reward Yourself
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/make-money/months-with-3-paychecks">
                        Months With Three Paychecks
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-3 social">
                 <section>
                  {/* <h4 className="footer-header">SUBSCRIBE</h4> */}
                  {/* form goes here */}
                  <h4 className="footer-header">FOLLOW US</h4>
                  <a title="linkedin" href="https://linkedin.com/company/pineapple-money">
                    <img
                      src={linkedin}
                      alt="LinkedIn"
                      style={{ width: "1.4em", height: "1.4em" }}
                    />
                  </a>
                  <a title="twitter" href="https://twitter.com/_pineapplemoney">
                    <img
                      className="fas fa-lg"
                      src={twitter}
                      alt="Twitter"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  <a title="instagram" href="https://www.instagram.com/_pineapplemoney/">
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  <a title="youtube" href="https://www.youtube.com/@pineapplemoney">
                    <img
                      src={youtube}
                      alt="YouTube"
                      style={{ width: "1.4em", height: "1.4em" }}
                    />
                  </a>
                  <div classname="footer-disclaimer">We only recommend products or services we believe create value for you. We may receive compensation when visitors make purchases after clicking links on our site.</div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          &copy; {new Date().getFullYear()} pineapplemoney.com
        </div>
        {/* make sure the footer looks good for mobile */}
      </footer>
    );
  }
};

export default Footer;
